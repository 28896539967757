@import "styles/variables";

$padding-top-11: 11px;
$arrow-size: 7px;

@mixin setColor($color) {
  color: $color;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $padding-top-11 $spacing-4;
  text-decoration: none;

  background-color: var(--color-base-2);
}

.addressAndTimeRow {
  display: flex;
  justify-content: space-between;
}

.arrow {
  position: absolute;
  right: $spacing-6;
  bottom: calc(100% - 60%);

  transform: rotate(45deg);
  width: $arrow-size;
  height: $arrow-size;
  border-right: 1px solid var(--color-fill-4);
  border-top: 1px solid var(--color-fill-4);

  ::before {
    content: " ";
  }
}

.addressName {
  @include setColor(var(--color-tint-action));
}

.date {
  @include setColor(var(--color-fill-1));
}

.senderName {
  @include setColor(var(--color-fill-1));
  width: calc(100% - 11.66%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shortMessage {
  @include setColor(var(--color-fill-3));
  width: calc(100% - 11.66%);
}
