@import "styles/variables";
@import "styles/common";
@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100%;
  box-sizing: border-box;
  padding: $spacing-8;

  background-color: var(--color-base-1);
}

.imgBox {
  min-width: 115px;
  min-height: 105px;
}

.heading {
  color: var(--color-fill-1);

  text-align: center;
  margin: 0;
  margin-top: $spacing-6;
}

.body {
  @extend %RobotoFont;
  color: var(--color-fill-3);

  text-align: center;
  margin: $spacing-4 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: $spacing-2 $spacing-4;
  border-radius: $spacing-2;
  background-color: var(--color-on-fill-1);
  margin-bottom: $spacing-4;
  box-sizing: border-box;
}

.label {
  color: var(--color-tint-action);

  margin-bottom: $spacing-1;
}

.address {
  box-sizing: border-box;
  width: 100%;
  background: var(--color-on-fill-1);
  padding: $spacing-2 $spacing-4;
  border-radius: 8px;
  margin-bottom: $spacing-4;
}

.addressTitle {
  color: var(--color-tint-action);
}

.addressName {
  color: var(--color-fill-1);
}

.addButton {
  margin-bottom: $spacing-4;
}
