@import "styles/variables";
@import "styles/breakpoints";
@import "styles/mixins";

.content {
  position: relative;
  overflow: auto;

  margin: 0 auto;

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.fullHeight {
    height: 100%;
  }
}
