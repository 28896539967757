@import "styles/breakpoints";

/*
    PX to EM or REM converter mixin. Please just use the
    pxToRem and pxToEm functions, and not the pxToRemOrRem.
    They can take multiple values, if need be. Useful for
    example when setting a padding short hand, like so:
    padding: pxToRem(10 5 15 15);
*/
$baseFont: 16;

@function pxToRemOrEm($pxValues, $unit: rem) {
  $remValues: null;

  @each $pxValue in $pxValues {
    $remValues: append($remValues, $pxValue / $baseFont + $unit);
  }

  @return $remValues;
}

@function pxToRem($pxValues) {
  @return pxToRemOrEm($pxValues, rem);
}

@function pxToEm($pxValues) {
  @return pxToRemOrEm($pxValues, em);
}

/*
    Mixin for media queries and breakpoints.
    Preferably follow mobile-first, and only use the max breakpoints if necessary

    The mixin can be used like this:

    @include media("desktop-small") {
        width: 50%;
    }
*/
$breakpoints: (
  "mobile-portrait-max": (
    max-width: $screen-mobile-landscape - 1px,
  ),
  "mobile-landscape": (
    min-width: $screen-mobile-landscape,
  ),
  "mobile-landscape-max": (
    max-width: $screen-tablet-portrait - 1px,
  ),
  "tablet-portrait": (
    min-width: $screen-tablet-portrait,
  ),
  "tablet-landscape": (
    min-width: $screen-tablet-landscape,
  ),
  "desktop-small-max": (
    max-width: $screen-desktop-small - 1px,
  ),
  "desktop-small": (
    min-width: $screen-desktop-small,
  ),
  "desktop-medium": (
    min-width: $screen-desktop-medium,
  ),
  "desktop-large": (
    min-width: $screen-desktop-large,
  ),
  "desktop-height-small": (
    min-height: $screen-desktop-small,
  ),
);

$orientations: (
  "landscape": (
    orientation: landscape,
  ),
);

@mixin media($breakpoint, $orientation: "") {
  @if map-has-key($map: $orientations, $key: $orientation) {
    $orientation: and #{inspect(map-get($orientations, $orientation))};
  }
  @if map-has-key($map: $breakpoints, $key: $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint)) $orientation} {
      @content;
    }
  } @else {
    @warn "The specified breakpoint `#{$breakpoint}` does not exits. Available breakpoints are: #{map-keys($breakpoints)}";
  }
}
