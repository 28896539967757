@import "styles/mixins";
@import "styles/variables.scss";

.label {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: $spacing-2 $spacing-4 $spacing-0;
  background: var(--color-base-2);
  color: var(--color-tint-action);
  font-size: pxToRem(13);
  line-height: 16px;
  border-top: 0.5px solid var(--color-separator);
}

.select {
  caret-color: var(--color-tint-action);
  background: var(--color-base-2);
  box-sizing: border-box;
  border-bottom: 0.5px solid var(--color-separator);

  [class$="-control"] {
    border-radius: 0;
    box-shadow: none;
    border: none;
    font-size: pxToRem(15);
    padding-left: 6px;

    [class$="-ValueContainer"] {
      padding-left: $spacing-0;

      [class$="-singleValue"] {
        margin-left: $spacing-0;
      }

      [class$="-placeholder"] {
        color: var(--color-fill-3);
        margin: $spacing-0;
      }

      [class$="-Input"] {
        color: var(--color-fill-1);
        margin: $spacing-0;
      }
    }
  }

  [class$="-menu"] {
    box-shadow: none;
    margin: $spacing-0;
    padding: $spacing-0;
    border-radius: 0;

    [class$="-MenuList"] {
      overflow-x: hidden;
      border: 0.5px solid var(--color-separator);
      border-width: 0.5px 0;
      padding: $spacing-0;
    }
  }

  [class$="-option"] {
    box-sizing: border-box;
    margin: $spacing-0;
    padding: $spacing-0;
    padding-left: $spacing-4;
    height: 56px;
    display: flex;
    align-items: center;
    font-size: pxToRem(16);
    position: relative;
    color: var(--color-fill-1);

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        width: calc(100% - 14px);
        border-top: 0.5px solid var(--color-separator);
        bottom: 0;
      }
    }
  }
}

.error {
  display: block;
  line-height: 20px;
  margin-top: $spacing-2;
  margin-left: $spacing-4;
  font-size: pxToRem(13);
  color: var(--color-fill-3);
}
