@import "styles/mixins";
@import "styles/common";

%base {
  @extend %SFProDisplayFont;

  &.bold {
    font-weight: bold;
  }
}

/* HEADING */
.heading {
  @extend %base;
  font-weight: 700;

  &.h1 {
    font-size: pxToRem(40);
    line-height: pxToRem(40);
  }

  &.h2 {
    font-size: pxToRem(24);
    line-height: pxToRem(28);
  }

  &.h3 {
    font-size: pxToRem(18);
    line-height: pxToRem(24);
  }
}

/* Title */
.title {
  @extend %base;
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: pxToRem(20);
}

/* Body */
.body {
  @extend %base;
  font-weight: 400;
  font-size: pxToRem(15);
  line-height: pxToRem(24);
}

.body1 {
  font-size: pxToRem(15);
  line-height: pxToRem(24);
}

.body2 {
  font-size: pxToRem(13);
  line-height: pxToRem(20);

  &.bold {
    font-weight: 500;
  }
}
