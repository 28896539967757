@import "styles/variables";

.container {
  min-height: 100%;
  background-color: var(--color-base-2);
  padding: $spacing-6 $spacing-4;
  box-sizing: border-box;
}

.addressName {
  color: var(--color-tint-action);
  margin-bottom: $spacing-2;
}

.headline {
  color: var(--color-fill-1);
  margin-bottom: $spacing-4;
}

.senderName {
  color: var(--color-fill-1);
  margin-bottom: $spacing-1;
}

.fullMessage {
  color: var(--color-fill-3);
  margin-bottom: $spacing-6;

  & a,
  & a:visited {
    color: var(--color-tint-action);
  }
}

.date {
  color: var(--color-fill-1);
  margin: $spacing-6 $spacing-0 $spacing-10;
}
