@import "styles/variables";
@import "styles/mixins";
@import "styles/common";

.button {
  overflow: hidden;
  border: none;

  font-weight: 700;

  &.small {
    padding: $spacing-1 $spacing-3;
    border-radius: $spacing-5;

    font-size: pxToRem(14);
    line-height: pxToRem(24);
  }

  &.meduim {
    padding: $spacing-2 $spacing-4;
    border-radius: $spacing-5;

    font-size: pxToRem(14);
    line-height: pxToRem(24);
  }

  &.large {
    padding: $spacing-4 $spacing-6;
    border-radius: $spacing-9;

    font-size: pxToRem(16);
    line-height: pxToRem(20);
  }

  &.fullWidth {
    width: 100%;
  }

  &.isDisabled {
    pointer-events: none;
    background: var(--color-separator);
    color: var(--color-fill-3);
  }

  &:focus {
    outline: 0;
  }
}

.primary {
  color: var(--color-on-fill-1);
  background: var(--color-tint-gradient);
}

.secondary {
  color: var(--color-fill-1);
  background: var(--color-separator);
}

.onFill {
  color: var(--color-on-fill-1);
  background: var(--color-separator);
}

/* Button Text */
.buttonText {
  // @extend %base;
  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(20);

  &.large {
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
  }

  &.small {
    font-weight: 700;
    font-size: pxToRem(14);
    line-height: pxToRem(24);
  }
}
