@import "styles/variables.scss";

.wrapper {
  background: var(--color-base-1);
  min-height: 100%;
  position: relative;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title {
  color: var(--color-fill-1);
  margin: $spacing-5 $spacing-4 $spacing-2;
}

.action {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  padding-left: $spacing-4;
  padding-bottom: $spacing-4;

  .cancel {
    width: 100%;
  }

  .submit {
    margin: $spacing-0 $spacing-4;
  }
}
