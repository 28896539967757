@import "styles/variables";

.wrapper {
  min-height: 100%;
  background-color: var(--color-base-1);
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: $spacing-4 $spacing-4 $spacing-3;
}

.header {
  color: var(--color-fill-1);
}

.cardsWrapper {
  background-color: var(--color-base-2);
}

.driftDivider {
  position: relative;
  left: $spacing-4;
  width: calc(100% - #{$spacing-4});
}

.noNotificationsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: $spacing-0 $spacing-8;
}

.subHeader {
  color: var(--color-fill-1);
  margin-bottom: $spacing-2;
}

.paragraph {
  color: var(--color-fill-3);
  text-align: center;
}
