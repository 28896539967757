@import "styles/variables.scss";

.modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--color-overlay);
  z-index: 1;
}

.body {
  box-sizing: border-box;
  position: absolute;
  background: var(--color-on-fill-1);
  width: 311px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-6;
  border-radius: 8px;
}

.description {
  color: var(--color-fill-3);
  text-align: center;
  margin-top: $spacing-2;
  margin-bottom: $spacing-3;
}

.deleteButton {
  margin-bottom: $spacing-3;
}
