@import "styles/variables.scss";

.container {
  background: var(--color-base-1);
  min-height: 100%;
}

.headingSection {
  padding: $spacing-4 $spacing-4 $spacing-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  color: var(--color-fill-1);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body {
  box-sizing: border-box;
  padding-left: $spacing-4;
  background: var(--color-on-fill-1);
  border: solid var(--color-separator);
  border-width: 0.5px 0;
  margin-bottom: $spacing-15;
}

.addressCard {
  padding-right: $spacing-4;
}

.action {
  padding: $spacing-4;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
}
