@import "styles/variables.scss";
@import "styles/mixins.scss";

.card {
  padding: $spacing-3 $spacing-0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.street {
  font-size: pxToRem(16);
  font-weight: $font-weight-500;
  color: var(--color-fill-1);
}

.details {
  color: var(--color-fill-3);
}

.action {
  background: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 0;
  border: none;
}
