$duration: 1.4s;
$thickness: 2px;
$size: 1.6em;

.loading {
  .spinner * {
    box-sizing: border-box;
  }

  .spinner {
    display: inline-block;
    animation-name: anim-spinner;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
      width: $size;
      height: ($size/2);
      overflow: hidden;
    }

    .circleInner {
      transform: rotate(45deg);
      border-radius: 50%;
      border: $thickness solid var(--color-tint-action);
      border-right: $thickness solid transparent;
      border-bottom: $thickness solid transparent;
      width: 100%;
      height: 200%;
      animation-name: anim-circle-1;
      animation-duration: $duration;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
    }

    .circle2 {
      transform: rotate(180deg);

      & .circleInner {
        animation-name: anim-circle-2;
      }
    }
    @keyframes anim-circle-1 {
      from {
        transform: rotate(60deg);
      }

      to {
        transform: rotate(205deg);
      }
    }
    @keyframes anim-circle-2 {
      from {
        transform: rotate(30deg);
      }

      to {
        transform: rotate(-115deg);
      }
    }
    @keyframes anim-spinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}
