@import "styles/colors";
@import "styles/normalize";
@import "styles/fonts/fonts";

html,
body,
#root {
  height: 100%;
  font-family: "SFProDisplay", "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

::-moz-focus-inner {
  border: 0;
}

// Fix for blurry images on retina https://stackoverflow.com/questions/13347947/css-filter-on-retina-display-fuzzy-images
img {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
