:root {
  --color-tint-action: #a8242e;
  --color-tint-gradient: linear-gradient(135deg, #bc1f30 0%, #872058 100%);

  --color-fill-1: #223944;
  --color-fill-2: #455c68;
  --color-fill-3: #5a7581;
  --color-fill-4: #7f9aa3;

  --color-on-fill-1: #ffffff;

  --color-separator: rgba(20, 62, 82, 0.1);

  --color-base-1: #f4f6f8;
  --color-base-2: #ffffff;

  --color-overlay: rgba(34, 57, 68, 0.6);
}
