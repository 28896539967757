// SF-Pro-Display
// whenever you try to transform to a different format it doesn't work correctly
// not sure why, but this font is downloaded from apple's website
@font-face {
  font-family: "SFProDisplay";
  src: url("./SFProDisplay/SFProDisplay-Light.otf") format("opentype");

  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./SFProDisplay/SFProDisplay-Regular.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./SFProDisplay/SFProDisplay-Bold.otf") format("opentype");

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
